<template>
  <BaseSearch v-model:model-value="value"/>
</template>

<script setup>
import BaseSearch from '@/components/base/BaseSearch.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { SEARCH_QUERY } from '@/assets/js/const';
import { useQuery } from '@/compositions/useQuery';
import { useStore } from 'vuex';

// TODO Убрать использование стора после переделки всех страниц
// временно вернул стор для обратной совместимости
// страниц которые еще не переделаны на кендо

const store = useStore();
const route = useRoute();
const query = route.query;
const { setQuery } = useQuery();

const value = computed({
  get() {
    return query[SEARCH_QUERY] || store.getters.getSearchValue;
  },
  set(newValue) {
    store.commit('SET_SEARCH', newValue);
    setQuery({ [SEARCH_QUERY]: newValue });
  },
});
</script>
