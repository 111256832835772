export default [
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "settings" */ '../../views/Settings/SettingsPageLayout'),
    name: 'settings',
    redirect: { name: 'settings.player' },
    children: [
      {
        path: 'player',
        component: () => import(/* webpackChunkName: "settings" */ '../../views/Settings/SettingsPlayer'),
        name: 'settings.player',
        meta: {
          title: 'Видеоконтент',
        },
      },
      {
        path: 'notifications',
        component: () => import(/* webpackChunkName: "settings" */ '../../views/Settings/SettingsNotifications'),
        name: 'settings.notifications',
        meta: {
          title: 'Уведомления',
        },
      },
      {
        path: 'security',
        component: () => import(/* webpackChunkName: "settings" */ '../../views/Settings/SettingsSecurity'),
        name: 'settings.security',
        meta: {
          title: 'Безопасность',
        },
      },
      {
        path: 'search',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsSearch.vue'),
        name: 'settings.search',
        meta: {
          title: 'Поиск',
        },
        props: true,
        children: [
          {
            path: 'stop-words',
            component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsSearchStopWords.vue'),
            name: 'settings.search.stop-words',
            meta: {
              title: 'Поиск.Стоп-слова',
            },
            props: { section: 'videos' },

          },
          {
            path: 'video',
            component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsSearchSynonyms.vue'),
            name: 'settings.search.synonyms',
            meta: {
              title: 'Поиск.Синонимы',
            },
            props: { section: 'videos' },
          },
          {
            path: 'example',
            component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsSearchExample.vue'),
            name: 'settings.search.example',
            meta: {
              title: 'Поиск.Пример',
            },
          },
        ],
      },
      {
        path: 'main',
        component: () => import(/* webpackChunkName: "settings" */ '../../views/Settings/SettingsMain'),
        name: 'settings.main',
        meta: {
          title: 'Главная',
        },
      },
      {
        path: 'subtitles',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsSubtitles.vue'),
        name: 'settings.subtitles',
        meta: {
          title: 'Субтитры',
        },
      },
      {
        path: 'complaintsCategories',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsCategoriesOfComplaints.vue'),
        name: 'settings.complaintsCategories',
        meta: {
          title: 'Категории жалоб',
        },
      },
      {
        path: 'template',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsTemplate.vue'),
        name: 'settings.template',
        meta: {
          title: 'Шаблон',
        },
      },
      {
        path: 'roles',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsRoles.vue'),
        name: 'settings.roles',
        meta: {
          title: 'Роли',
        },
      },
      {
        path: 'converter',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsConverter.vue'),
        name: 'settings.converter',
        meta: {
          title: 'Настройки конвертера',
        },
      },
      {
        path: 'sms',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsSms.vue'),
        name: 'settings.sms',
        meta: {
          title: 'SMS',
        },
      },
      {
        path: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Settings/AboutUs.vue'),
        name: 'settings.about',
        meta: {
          title: 'О нас',
        },
      },
      {
        path: 'seo',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsSeo.vue'),
        name: 'settings.seo',
        meta: {
          title: 'SEO',
        },
      },
    ],
  },
];
