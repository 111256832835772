export const DEFAULT_PER_PAGE = 15;
export const DEFAULT_PAGE_SIZES = [10, 20 ,30];
export const INPUT_DEBOUNCE_TIME_MS = 750;
export const SORT_BY_QUERY = 'sort_by';
export const SORT_ORDER_QUERY = 'order';
export const PAGE_QUERY = 'page';
export const PER_PAGE_QUERY = 'per_page';
export const SEARCH_QUERY = 'search';
export const IS_UPLOADED_QUERY = 'is_uploaded';
export const CONVERT_STEP_QUERY = 'convert_step';
export const VERIFY_STATUS_QUERY = 'status';
export const ACCOUNT_TYPE_QUERY = 'type';
export const IS_BLOCKED_QUERY = 'is_blocked';
export const IS_DELETED_QUERY = 'onlyTrash';
export const DATE_FROM_QUERY = 'date_from';
export const DATE_TO_QUERY = 'date_to';
export const NAME_QUERY = 'name';
export const LOGIN_QUERY = 'login';
export const CREATEDATE_QUERY = 'created_at';
export const HAPPENEDDATE_QUERY = 'happened_at';
export const REPORTS_COUNT_QUERY = 'reports_count';
export const COPYRIGHT_REPORTS_COUNT = 'copyright_reports_count';
export const STREAM_STATUS_QUERY = 'stream_status';
export const MODERATION_STATUS_QUERY = 'moderation_status';
export const VIEW_MODE_QUERY = 'view';
export const CHANNEL_QUERY = 'channel_id';
export const ACCOUNT_QUERY = 'account_id';
export const MODERATOR_QUERY = 'personal_id';
export const FILTER_VERIFY_STATUS_QUERY = 'verification_status';
export const ACCOUNT_FAKE_STATUS_QUERY = 'fake_status';
export const COMMENT_QUERY = 'video_id';
export const USER_QUERY = 'user_id';
export const FILE_SIZE_QUERY = 'size_data';
export const CONTENT_REPORT_STATUS_QUERY = 'status';
export const TAX_DATA_STATUS_QUERY = 'tax_data_status';
export const TRANSACTION_STATUS_QUERY = 'transaction_status';
export const CHANNEL_OWNER_QUERY = 'channel_owner_id';
export const OWNER_QUERY = 'owner_id';
export const NEWS_TYPE_QUERY = 'news_type';
export const NEWS_STATUS_QUERY = 'news_status';
export const NOTIFICATION_STATUS_QUERY = 'notification_status';
export const SMS_STATUS_QUERY = 'sms_status';
export const FILE_TYPE_QUERY = 'file_type';
export const VIDEO_QUERY = 'video_id';
