import { http } from '@/plugins/axios';

export default {
  async getVideoClassifications(videoId) {
    const { data } = await http.get(`/api/v1/video/${videoId}/classifications`);
    return data;
  },

  async storeClassification(payload) {
    const { data } = await http.post('/api/v1/classification', payload);
    return data;
  },
};